h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $baseline;
  font-family: $header-font-family;
  line-height: $header-line-height;
  font-weight: normal;
  color: $dark-blue;
}

h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: calc(#{$h1} / 1.5);

  @include bp(tablet) {
    font-size: $h1;
  }
}

h2 {
  font-size: calc(#{$h2} / 1.2);

  @include bp(tablet) {
    font-size: $h2;
  }
}

h3 {
  font-size: calc(#{$h3} / 1.2);

  @include bp(tablet) {
    font-size: $h3;
  }
}

h4 {
  font-size: calc(#{$h4} / 1.2);

  @include bp(tablet) {
    font-size: $h4;
  }
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

p {
  color: $text-color;
  font-family: $global-font-family;
  font-weight: $global-weight-normal;
  line-height: $baseline;
  margin-bottom: $baseline;
}

a {
  line-height: inherit;
  color: $text-color;

  &:active {
    color: $text-color;
  }

  &:focus {
    color: $text-color;
  }

  &:hover {
    color: darken($text-color, 10%);
  }
}

.section-heading {
  margin-bottom: 1.5rem;
  font-size: 3rem;
  line-height: 4rem;
  color: $dark-blue;
  text-align: center;

  @include bp(tablet) {
    font-size: 4.2rem;
    line-height: 5.4rem;
  }

  &.section-heading--left {
    text-align: left;
  }
}

// Blockquotes
blockquote {
  position: relative;
  margin: 0 0 4rem;
  padding: 1rem 4rem;
  border-left: 8px solid $primary;
  font-size: 2.8rem;

  &:before {
    position: absolute;
    top: -3rem;
    left: 1rem;
    content: "“";
    font-family: georgia, serif;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 15rem;
    z-index: 0;
  }

  p {
    font-size: 2.4rem !important;
    font-weight: 700;
    color: $text-color;
    line-height: 3rem;
    position: relative;
    z-index: 1;
  }
}

// Citations
cite {
  display: block;
  font-size: $cite-font-size;
  color: $cite-color;

  &:before {
    content: $cite-pseudo-content;
  }
}

// Abbreviations
abbr {
  border-bottom: $abbr-underline;
  color: $darkest;
  cursor: help;
}

strong {
  font-family: $header-font-family;
  // font-weight: 700;
}

// Figures
figure {
  margin: 0;
}

// Horizontal rule
hr {
  max-width: 100%;
  height: 0;
  margin: $global-margin;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid $light-grey;
  border-left: 0;
}
