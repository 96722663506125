.block__accordions {
  background: $lightest;
  
  &.in-view {
    .accordions__intro {
      @include animate(400ms, 400ms, fadeIn);
      opacity: 1;
    }

    .accordions__item {
      @include animate(400ms, 0, fadeInDown);

      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          animation-delay: (0.2s * $i);
          opacity: 1;
        }
      }
    }
  }

  .accordions__intro {
    margin-bottom: 5rem;
    opacity: 0;
  }

  .accordions__item {
    opacity: 0;
  }
}
