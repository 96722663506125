footer[role="contentinfo"] {
  background: $darkest;
  padding: 0;

  @include bp(tablet) {
    padding: 0 0 3rem 0;
  }

  @include bp(desktop) {
    padding: 0 0 5rem 0;
  }

  p,
  li,
  a {
    font-size: 1.6rem;
    line-height: 3rem;

    @include bp(tablet) {
      font-size: 1.8rem;
      line-height: 3.2rem;
    }
  }

  .footer__ai {
    background-color: $pink;

    .container {
      padding: 3rem 0;

      @include bp(tablet) {
        padding: 5rem 0;
      }

      @include bp(desktop) {
        padding: 7.5rem 0;
      }
    }

    .row {
      margin-bottom: 3rem;

      @include bp(tablet) {
        margin-bottom: 5rem;
      }

      &.border {
        padding-top: 4rem;
        border-top: 2px solid $darkest;

        @include bp(tablet) {
          padding-top: 7.5rem;
        }
      }

      &:last-of-type {
        margin-bottom: 0;

        @include bp(tablet) {
          margin-bottom: 0;
        }
      }
    }

    &-newsletter {
      width: 100%;
      height: 100%;
      margin-bottom: 4rem;
      padding: 1rem;
      // background-color: $dark-blue;
      background-image: url("../../assets/images/footer-form-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;

      @include bp(tablet) {
        margin-bottom: 7.5rem;
      }

      .ai-newsletter__inner {
        width: 100%;
        height: auto;
        padding: 2rem;
        background-color: $lightest;
        border-radius: 20px;

        @include bp(tablet) {
          padding: 4rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
        }

        @include bp(desktop) {
          padding: 5rem;
        }

        h3 {
          flex: 1;
          margin: 0;
          display: flex;
          font-size: 2rem;
          line-height: 2.4rem;
          margin-bottom: 20px;

          @include bp(tablet) {
            font-size: 2.4rem;
            line-height: 3rem;
            margin-bottom: 0;
          }

          @include bp(desktop) {
            width: 50%;
            max-width: 50%;
            font-size: 2.8rem;
            line-height: 3.5rem;
          }

          svg {
            width: 50px;
            height: auto;
            margin-right: 2rem;
            display: inline-block;
  
            @include bp(desktop) {
              width: 100px;
            }
          }

        }

        form {
          width: 100%;
          height: auto;
          margin: 2rem 0 0 0;
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;

          @include bp(tablet) {
            margin: 3rem -1rem -2rem -1rem;
          }

          @include bp(desktop) {
            margin: 3rem 0 0 0;
          }

          div {
            width: 100%;
            margin: 0 0 2rem 0;

            @include bp(tablet) {
              width: calc(50% - 2rem);
              margin: 0 1rem 2rem 1rem;
            }

            @include bp(desktop) {
              flex: 1;
              margin: 0 4rem 0 0;
            }
          }

          label {
            margin-bottom: 1rem;
            display: block;
            font-size: 1.8rem;
            line-height: 2.2rem;
            color: $darkest;

            @include bp(desktop) {
              margin-bottom: 1.5rem;
              font-size: 2rem;
              line-height: 2.5rem;
            }
          }

          input {
            height: 50px;
            margin: 0;
            background-color: $lightest;
            border: 2px solid $darkest;
            border-radius: 4px;
          }

          button {
            height: 50px;
            margin: 0;
            font-size: 2rem;
            line-height: 2.5rem;

            @include bp(tablet) {
              margin: 0 1rem 2rem 1rem;
            }

            @include bp(desktop) {
              margin: 0;
            }
          }
        }
      }
    }

    &-main {
      img,
      svg {
        width: 180px;
        height: auto;
        margin-bottom: 2.5rem;
        display: block;

        @include bp(tablet) {
          width: 220px;
        }

        @include bp(desktop) {
          width: 250px;
        }
      }

      p {
        margin-top: 0;
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: $darkest;
      }

      h4 {
        margin-bottom: 1rem;
      }
    }

    &-nav {
      margin-top: 3rem;
      display: flex;

      @include bp(tablet) {
        margin-top: 0;
        justify-content: flex-end;
      }

      nav {
        @include bp(tablet) {
          column-count: 2;
          column-gap: 100px;
        }

        a {
          margin-bottom: 1rem;
          display: block;
          font-family: $header-font-family;
          font-size: 2rem;
          line-height: 2.5rem;
          text-decoration: none;
          color: $dark-blue;
        }
      }
    }

    &-socials {
      display: flex;

      a {
        text-decoration: none;
      }

      img,
      svg {
        width: 60px;
        height: auto;
        margin-right: 2rem;
        display: block;
      }
    }

    &-credits {
      margin-top: 2rem;
      display: flex;

      @include bp(tablet) {
        margin-top: 0;
        justify-content: flex-end;
      }

      a.credits {
        margin: 0;
        position: relative;
        display: inline-block;
        font-size: 1.6rem;
        line-height: 2.2rem;
        text-decoration: none;
        text-align: left;
        overflow: hidden;

        @include bp(tablet) {
          margin: 0;
          font-size: 1.8rem;
          line-height: 2.3rem;
          text-align: right;
        }

        div,
        span {
          position: absolute;
          top: 0;
          left: 0;
          transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
          width: 100%;
          text-align: left;

          @include bp(tablet) {
            text-align: right;
          }
        }

        div {
          transform: translateY(-100%);

          @include bp(desktop) {
            transform: translateY(0%);
          }
        }

        span {
          display: block;
          width: 300px;
          height: 30px;
          transform: translateY(0%);

          @include bp(desktop) {
            transform: translateY(100%);
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        &:hover {
          div {
            transform: translateY(-100%);
          }

          span {
            transform: translateY(0%);
          }
        }
      }
    }
  }

  .footer__upper {
    margin: 0 0 3rem 0;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: $darkest;
    border-bottom: 1px solid $lightest;

    @include bp(tablet) {
      margin: 0 0 5rem 0;
      padding: 5rem 0;
    }

    .row {
      flex-direction: column;
      align-items: flex-start;

      @include bp(tablet) {
        flex-direction: row;
        align-items: center;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li {
      color: $lightest;
    }

    a:not(.button):hover {
      color: $green;
    }

    .footer__logos {
      display: flex;
      flex-direction: column;

      @include bp(tablet) {
        flex-direction: row;
      }

      a {
        margin-bottom: 2rem;
        display: block;

        @include bp(tablet) {
          margin-bottom: 0;
          margin-right: 3rem;

          &:last-child {
            margin-right: 0;
          }
        }

        @include bp(desktop) {
          margin-right: 7rem;
          margin-bottom: 0;
        }

        img {
          width: auto;
          height: 30px;
          display: block;

          @include bp(tablet) {
            height: 25px;
          }

          @include bp(desktop) {
            height: 40px;
          }
        }
      }
    }
  }

  .footer__lower {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li {
      color: $lightest;
      text-decoration: none;
    }

    a:not(.button) {
      transition: 400ms $cubic;

      &:hover {
        color: $green;
      }
    }

    h2 {
      margin-bottom: 1rem;
      font-size: 1.6rem;
      // font-weight: 700;
      line-height: 2rem;
    }

    .footer__navigation {
      margin-bottom: 2rem;

      @include bp(desktop) {
        margin-bottom: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;

          a {
            font-size: 1.6rem;
            line-height: 2rem;
            text-decoration: none;
          }
        }
      }

      .cambridge-uni-quicklinks-footer {
        margin-top: 2rem;
        display: block;

        @include bp(tablet) {
          display: none;
        }
      }
    }
  }
}
