// Custom Button Styles
.button-box {
  margin: 0 -1.5rem -3rem -1.5rem;
  display: flex;
  justify-content: flex-start;
}

.button {
  &.button--standard {
    margin: 0 1.5rem 3rem 1.5rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-radius: 32px;
    background-color: $green;
    border: none;
    box-shadow: none;
    font-family: $header-font-family;
    font-size: 2rem;
    line-height: 2.5rem;
    text-decoration: none;
    color: $dark-blue;
    cursor: pointer;

    @include bp(tablet) {
      padding: 2rem;
    }

    &.button--blue {
      background-color: $blue;
      color: $lightest;
    }

    &.button--standard-anchor {
      &::after {
        content: "";
        width: 24px;
        height: 21px;
        margin-left: 1rem;
        display: block;
        background-image: url("../../assets/images/arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  &.button--filters {
    margin: 0;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-radius: 32px;
    background-color: $green;
    border: none;
    box-shadow: none;
    font-family: $header-font-family;
    font-size: 2rem;
    line-height: 2.5rem;
    text-decoration: none;
    color: $dark-blue;

    @include bp(tablet) {
      padding: 2rem;
    }
  }

  &.button--simple {
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    font-family: $header-font-family;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $blue;

    &::after {
      content: "";
      width: 16px;
      height: 18px;
      margin-left: 1rem;
      display: block;
      transform: rotate(-90deg);
      background-image: url("../../assets/images/arrow-blue.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
