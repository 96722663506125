.block__latest-overview {
  background: $lightest;
  
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @include bp(tablet) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include bp(desktop) {
      grid-gap: 30px;
    }
  }

  .latest-overview__column {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 2.8rem;
      line-height: 3.5rem;

      @include bp(tablet) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }

      @include bp(desktop) {
        font-size: 2.8rem;
        line-height: 3.5rem;
      }
    }
  }
}
