.block__hero {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.block--bg-blue {
    background-color: $dark-blue;

    .hero__content {
      h1,
      p {
        color: $lightest;
      }
    }
  }

  &.in-view {
    .hero__shape {
      &.hero__shape-1 {
        clip-path: ellipse(110% 110% at 0% 0%);
      }

      &.hero__shape-2 {
        clip-path: ellipse(110% 110% at 100% 0%);
      }

      &.hero__shape-3 {
        clip-path: ellipse(110% 110% at 0% 100%);
      }

      &.hero__shape-4 {
        clip-path: ellipse(110% 110% at 100% 100%);
      }
    }

    .hero__content {
      @include animate(400ms, 400ms, fadeIn);
      opacity: 1;
    }

    .hero__image,
    .hero__filters,
    .hero__image-image {
      @include animate(400ms, 700ms, fadeIn);
      opacity: 1;
    }

    .hero__image-image--floating {
      @include animate(400ms, 900ms, fadeIn);
      opacity: 1;
    }
  }

  &.block__hero--large,
  &.block__hero--slider {
    padding: 10rem 0 4rem 0;

    @include bp(tablet) {
      min-height: 100vh;
      padding: 15rem 0 5rem 0;
    }

    @include bp(desktop) {
      padding: 12.5rem 0 5rem 0;
    }
  }

  &.block__hero--slider {
    .slick-list {
      margin: 0 -4rem;
      box-shadow: none;
      background: transparent;
      border: none;
    }

    .slick-slide {
      margin: 0 4rem;
      box-shadow: none;
      background: transparent;
      border: none;
    }

    .slick-dots {
      display: flex;
      text-align: left;

      li {
        width: auto;
        height: auto;
        margin: 0 1.5rem 0 0;

        &.slick-active {
          button {
            max-width: 100px;
            background-color: $dark-blue;
            border-radius: 30px;
            transition: 400ms $cubic !important;
            transition-delay: 400ms !important;
          }
        }

        button {
          width: 75px;
          max-width: 25px;
          height: 25px;
          background: transparent;
          border: 2px solid $dark-blue;
          border-radius: 50%;
          transition: 400ms $cubic;
          transition-delay: 0ms;

          &::before {
            display: none;
          }
        }
      }
    }

    .hero__slider {
      width: 100%;
      height: auto;
    }

    .hero__slider-slide {
      position: relative;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      z-index: 2;
    }
  }

  &.block__hero--overflow {
    overflow: visible;
  }

  &.block__hero--standard {
    padding: 10rem 0 4rem 0;

    @include bp(tablet) {
      min-height: 540px;
      padding: 15rem 0 5rem 0;
    }

    @include bp(desktop) {
      padding: 12.5rem 0 5rem 0;
    }

    @include bp(desktop-xl) {
      min-height: 640px;
    }

    .hero__shape {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      @include animate(1s, 0, fadeIn);
      z-index: 0;
      opacity: 0.1;

      @include bp(desktop) {
        opacity: 0.3
      }

      @include bp(desktop-xl) {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.block__hero--archive,
  &.block__hero--search {
    padding: 10rem 0 5rem 0;;

    @include bp(tablet) {
      padding: 15rem 0 10rem 0;
    }

    @include bp(desktop) {
      padding: 20rem 0 10rem 0;
    }

    @include bp(desktop-xl) {
      padding: 20rem 0 12.5rem 0;
    }

    .hero__shape {
      position: absolute;
      top: 0;
      right: 0;
      @include animate(1s, 0, slideInRight);
      z-index: -1;
      opacity: 0.1;
    }

    .hero__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin-top: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .button {
        margin: 3rem 0 0 0;
      }
    }
  }

  &.block__hero--archive {
    position: relative;

    .container {
      position: relative;
      display: flex;
    }

    .hero__filters {
      position: relative;
    }
  }

  &.block__hero--search {
    position: relative;

    .hero__content {
      form {
        margin-top: 2rem;
        width: 100%;

        @include bp(tablet) {
          width: 500px;
        }

        input {
          margin: 0;
          padding: 1rem 2rem;
          background-color: $lightest;
          border-radius: 30px;
          color: $primary;

          &:placeholder-shown,
          &::placeholder {
            color: $primary;
          }
        }
      }
    }
  }

  .hero__shape {
    position: absolute;

    img,
    svg {
      width: 100%;
      height: auto;
      display: block;
    }

    &.hero__shape-1 {
      width: 140px;
      top: 0;
      left: 0;
      clip-path: ellipse(0% 0% at 0% 0%);
      transition: 1.7s $cubic;
      transition-delay: 500ms;

      @include bp(tablet) {
        width: 270px;
      }

      @include bp(desktop-xl) {
        width: 380px;
      }
    }

    &.hero__shape-2 {
      width: 80px;
      top: 0;
      right: 0;
      clip-path: ellipse(0% 0% at 100% 0%);
      transition: 1.2s $cubic;
      transition-delay: 900ms;

      @include bp(tablet) {
        width: 220px;
      }

      @include bp(desktop-xl) {
        width: 280px;
      }
    }

    &.hero__shape-3 {
      width: 120px;
      bottom: 0;
      left: 0;
      clip-path: ellipse(0% 0% at 0% 100%);
      transition: 1.5s $cubic;
      transition-delay: 800ms;

      @include bp(tablet) {
        width: 220px;
      }

      @include bp(desktop-xl) {
        width: 330px;
      }
    }

    &.hero__shape-4 {
      width: 180px;
      bottom: 0;
      right: 0;
      clip-path: ellipse(0% 0% at 100% 100%);
      transition: 1.3s $cubic;
      transition-delay: 700ms;

      @include bp(tablet) {
        width: 270px;
      }

      @include bp(desktop-xl) {
        width: 380px;
      }
    }
  }

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;
  }

  .hero__content {
    width: 100%;
    opacity: 0;

    @include bp(tablet) {
      width: 50%;
    }

    h1 {
      margin-bottom: 1rem;
      font-size: 3rem;
      line-height: 4rem;
      color: $dark-blue;

      @include bp(tablet) {
        font-size: 3.8rem;
        line-height: 5rem;
      }

      @include bp(desktop) {
        font-size: 5rem;
        line-height: 6.6rem;
      }

      // @include bp(desktop-xl) {
      //   font-size: 6.8rem;
      //   line-height: 8rem;
      // }
    }

    p {
      font-size: 1.8rem;
      line-height: 2.4rem;

      @include bp(tablet) {
        font-size: 2rem;
        line-height: 3rem;
      }

      @include bp(desktop) {
        font-size: 2.2rem;
        line-height: 3.4rem;
      }
      // @include bp(desktop-xl) {
      //   font-size: 2.4rem;
      //   line-height: 3.8rem;
      // }
    }
  }

  .hero__image,
  .hero__filters {
    width: 100%;
    margin-top: 3rem;
    opacity: 0;

    @include bp(tablet) {
      margin-top: 3rem;
      width: calc(50% - 5rem);
    }

    @include bp(desktop) {
      margin-top: 0;
    }

    @include bp(desktop) {
      width: calc(50% - 10rem);
    }
  }
}

.hero__image {
  &-image {
    width: 100%;
    height: auto;
    display: block;
    opacity: 0;
    border-radius: 20px;

    &--floating {
      width: 35%;
      height: auto;
      position: absolute;
      bottom: -4rem;
      right: 2rem;
      display: block;
      opacity: 0;
      border-radius: 20px;

      @include bp(tablet) {
        width: 255px;
        bottom: -6rem;
        right: 3rem;
      }
    }
  }
}

.hero__filters {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include bp(tablet) {
    justify-content: flex-end;
  }

  &.open {
    .hero__filters-selection {
      opacity: 1;
      visibility: visible;
    }
  }

  .button.button--standard {
    margin: 0;
    padding: 1rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 32px;
    background-color: $green;
    border: none;
    box-shadow: none;
    font-family: $header-font-family;
    font-size: 1.6rem;
    line-height: 2rem;
    text-decoration: none;
    color: $dark-blue;
    overflow: hidden;

    @include bp(tablet) {
      padding: 1.5rem 2rem;
    }

    @include bp(desktop-xl) {
      font-size: 2rem;
      line-height: 2.5rem;
      padding: 2rem;
    }

    &.open {
      .inactive {
        transform: translateY(-200%);
      }

      .active {
        transform: translateY(0%);
      }
    }

    .inactive {
      transform: translateY(0%);
      transition: 400ms $cubic;
    }

    .active {
      position: absolute;
      left: 2rem;
      transform: translateY(200%);
      transition: 400ms $cubic;
    }
  }

  .hero__filters-selection {
    width: auto;
    padding: 2rem 3rem;
    background-color: $lightest;
    border-radius: 10px;
    position: absolute;
    bottom: -1rem;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    transition: 400ms $cubic;
    z-index: 10;

    @include bp(tablet) {
      padding: 2rem 2.5rem;
      border-radius: 60px;
    }

    @include bp(desktop) {
      right: 0;
    }

    @include bp(desktop-xl) {
      padding: 2.5rem 3rem;
    }

    &-inner {
      margin: 0 -1rem -1rem -1rem;
      display: flex;
      flex-direction: column;

      @include bp(desktop-xl) {
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin: 0 -1.5rem -1.5rem -1.5rem;
      }
    }

    a {
      width: auto;
      margin: 0 1rem 1rem 1rem;
      display: block;
      font-family: $header-font-family;
      font-size: 1.6rem;
      line-height: 2rem;
      text-decoration: none;
      text-transform: uppercase;
      color: $blue;
      transition: 400ms $cubic;

      @include bp(desktop-xl) {
        margin: 0 1.5rem 1.5rem 1.5rem;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      &:hover {
        color: $blue;
      }
    }
  }
}
