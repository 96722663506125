.accordions {
  width: 100%;
  display: flex;
  flex-direction: column;

  .accordions__item {
    width: 100%;
    margin-bottom: 2rem;
    padding: 1.5rem 2rem 1.5rem 2rem;
    border: 1px solid $pink;

    @include bp(tablet) {
      margin-bottom: 3rem;
      padding: 2.5rem 2.5rem 2.5rem 2rem;
    }

    &.open {
      button {
        img {
          transform: rotate(-90deg);
        }
      }
      .accordions__item-inner {
        max-height: 2000px;
      }
    }

    button {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border: none;
      box-shadow: none;
      cursor: pointer;

      h3 {
        max-width: 85%;
        margin: 0;
        font-size: 1.8rem;
        // font-weight: 700;
        line-height: 2.4rem;
        text-align: left;
        color: $darkest;

        @media (min-width: 768px) {
          font-size: 2.6rem;
          line-height: 3.2rem;
        }
      }

      img {
        width: 12px;
        height: auto;
        display: block;
        transform: rotate(90deg);
        transition: 400ms $cubic;
        transform-origin: center center;

        @include bp(tablet) {
          width: 15px;
        }
      }
    }

    .accordions__item-inner {
      max-height: 0px;
      transition: 400ms $cubic;
      overflow: hidden;

      p,
      li,
      a:not(.button) {
        font-size: 1.6rem;
        line-height: 2.2rem;

        @include bp(tablet) {
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
