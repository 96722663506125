.block__opportunities {
  .opportunities__team {
    margin-bottom: 3rem;
    padding: 2.5rem;
    background-color: $lightest;
    border-radius: 24px;

    @include bp(tablet) {
      margin-bottom: 6rem;
      padding: 5rem;
    }

    &.open {
      .opportunities__team-listings {
        max-height: 2000px;
      }
    }

    .opportunities__team-upper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      .team-upper__content {
        order: 2;
        width: 100%;
        margin-bottom: 2rem;

        @include bp(desktop) {
          order: unset;
          width: calc(50% - 3rem);
        }

        h3 {
          margin-bottom: 1rem;
          font-size: 3rem;
          line-height: 4rem;

          @include bp(tablet) {
            font-size: 3.8rem;
            line-height: 4.9rem;
          }
        }

        p {
          margin-top: 0;
          font-size: 1.8rem;
          line-height: 2.4rem;

          @include bp(tablet) {
            font-size: 2rem;
            line-height: 2.6rem;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .button.button--standard {
          margin: 3rem 0 0 0;
          border: none;
          box-shadow: none;
          cursor: pointer;

          &.toggle-opportunities {
            position: relative;
            overflow: hidden;

            &.open {
              .inactive {
                transform: translateY(-200%);
              }

              .active {
                transform: translateY(0%);
              }

              svg {
                transform: rotate(180deg);
              }
            }

            .inactive {
              transform: translateY(0%);
              transition: 400ms $cubic;
            }

            .active {
              position: absolute;
              left: 1rem;
              transform: translateY(200%);
              transition: 400ms $cubic;

              @include bp(tablet) {
                left: 2rem;
              }
            }

            svg {
              margin-left: 2rem;
              transform: rotate(0);
              transition: 400ms $cubic;
            }
          }
        }
      }
      .team-upper__image {
        order: 1;
        width: 100%;
        margin-bottom: 2rem;

        @include bp(desktop) {
          order: unset;
          width: calc(50% - 3rem);
          margin-bottom: 0;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    .opportunities__team-listings {
      max-height: 0px;
      transition: 400ms $cubic;
      overflow: hidden;

      &__inner {
        padding-top: 5rem;
      }

      .team-listings__opportunity {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $darkest;
        font-family: $header-font-family;
        font-size: 1.8rem;
        text-decoration: none;
        color: $dark-blue;

        @include bp(desktop) {
          font-size: 2.4rem;
        }

        &:last-of-type {
          padding-bottom: 0;
          border-bottom: 0;
        }

        svg {
          width: 80px;
          height: auto;
          margin-left: 10rem;
          display: block;
        }
      }
    }
  }
}
