.block__team {
  background: $lightest;
  
  .team__intro {
    margin-bottom: 6.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &-filters {
      position: sticky;
      top: 100px;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $dark-cream;
      padding-bottom: 20px;
    }

    &-content {
      width: 100%;
      margin-bottom: 2rem;

      @include bp(desktop) {
        width: 75%;
        max-width: 930px;
        margin-bottom: 0;
      }

      h2 {
        margin-bottom: 2rem;
        font-size: 3rem;
        line-height: 4rem;

        @include bp(desktop) {
          font-size: 4.8rem;
          line-height: 8rem;
        }
      }

      p {
        margin-top: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: $dark-blue;

        @include bp(desktop) {
          font-size: 2rem;
          line-height: 2.6rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &-filters {
    }
  }

  .team__selector {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include bp(tablet) {
      margin-bottom: 3rem;
      flex-direction: row;
    }

    @include bp(desktop-xl) {
      margin-bottom: 6rem;
    }

    button {
      margin-bottom: 1rem;
      display: block;
      background: transparent;
      border: none;
      box-shadow: none;
      font-family: $header-font-family;
      font-size: 2.4rem;
      line-height: 3rem;
      color: $violet;
      cursor: pointer;

      @include bp(tablet) {
        margin-bottom: 2rem;
        margin-right: 5rem;
        line-height: 8rem;
      }

      @include bp(desktop) {
        font-size: 3.8rem;
      }

      @include bp(desktop-xl) {
        margin-right: 15rem;
      }

      &.active {
        color: $dark-blue;
      }
    }
  }

  .team__grid {
    display: none;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @include bp(tablet) {
      grid-gap: 20px;
    }

    @include bp(desktop) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }

    &.active {
      display: grid;
    }

    &.team__grid--basic {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include bp(tablet) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include bp(desktop) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .team__member {
        padding: 1rem;

        .team__member-image {
          margin-right: 2rem;

          @include bp(tablet) {
            margin-right: 5rem;
          }

          @include bp(desktop) {
            margin-right: 2rem;
          }
        }

        .team__member-content {
          h3 {
            margin: 5px 0;
            font-size: 1.6rem;

            @include bp(tablet) {
              font-size: 1.8rem;
            }

            @include bp(desktop) {
              font-size: 2rem;
            }

          }

          p {
            font-size: 1.4rem;
            line-height: 1.8rem;

            @include bp(tablet) {
              font-size: 1.6rem;
              line-height: 2rem;
            }

            @include bp(desktop) {
              font-size: 1.4rem;
              line-height: 1.8rem;
            }

            @include bp(desktop-xl) {
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }
        }
      }
    }

    .team__member {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $pink;
      border-radius: 300px;
      text-decoration: none;

      @include bp(tablet) {
        padding: 1.5rem;
        align-items: flex-start;
      }

      &-image {
        width: 25%;
        height: auto;
        margin-right: 2rem;
        position: relative;

        @include bp(tablet) {
          margin-right: 5rem;
        }

        @include bp(desktop) {
          width: 30%;
        }

        &::before {
          content: "";
          padding-top: 100%;
          display: block;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      &-content {
        flex: 1;

        h3 {
          margin: 0 0 5px 0;
          font-size: 1.8rem;

          @include bp(tablet) {
            margin: 0 0 1rem 0;
            font-size: 2.8rem;
          }

          @include bp(desktop-xl) {
            font-size: 3.2rem;
          }
        }

        p {
          margin: 0;
          font-size: 1.4rem;
          line-height: 1.6rem;

          @include bp(tablet) {
            font-size: 1.8rem;
            line-height: 2.2rem;
          }

          @include bp(desktop-xl) {
            font-size: 2rem;
            line-height: 2.4rem;
          }
        }
      }
    }
  }
}
