.block__contact {
  .contact__forms {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @include bp(desktop) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }
  }

  .contact__form {
    padding: 2.5rem;
    background-color: $lightest;
    border-radius: 12px;

    @include bp(tablet) {
      padding: 5rem;
    }

    &-intro {
      margin-bottom: 3rem;
      h3 {
        margin-bottom: 1.5rem;
        font-size: 3rem;
        line-height: 4rem;

        @include bp(tablet) {
          font-size: 3.2rem;
          line-height: 4rem;
        }
      }

      p {
        margin-top: 0;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: $dark-blue;

        @include bp(tablet) {
          font-size: 2rem;
          line-height: 2.6rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    label {
      margin-bottom: 1rem;
      display: block;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    input,
    textarea {
      background-color: $pink;
      border: 2px solid $darkest;
      border-radius: 4px;
      resize: none;
    }

    .button {
      margin: 3rem 0 0 0;
    }
  }
}
