/*
*
* Academic Report/Publication Styles
*
*/

.report {
  background-color: $lightest;
  padding: 4rem 0;

  @include bp(tablet) {
    padding: 6rem 0;
  }

  @include bp(desktop) {
    padding: 8rem 0;
  }

  // Publication Header
  &__header {
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $regular-grey;

    .title {
      margin-bottom: 2rem;
      font-family: $header-font-family;
      font-size: 3.2rem;
      line-height: 1.2;
      color: $dark-blue;

      @include bp(desktop) {
        font-size: 4.2rem;
      }
    }

    .subtitle {
      margin-bottom: 2rem;
      font-size: 2.4rem;
      line-height: 1.4;
      color: $primary;
    }
  }

  // Authors List
  &__authors {
    margin: 2rem 0;
    
    .author {
      margin-bottom: 1rem;
      
      &-name {
        font-size: 2rem;
        line-height: 1.4;
        color: $primary;
        font-weight: $global-weight-bold;
      }

      &-affiliation {
        font-size: 1.6rem;
        color: $darkest-grey;
      }

      &-email {
        color: $blue;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // Publication Meta
  &__meta {
    margin: 2rem 0;
    padding: 2rem;
    background: $light-grey;
    border-radius: 8px;

    dl {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 1rem 2rem;
      
      dt {
        font-weight: $global-weight-bold;
        color: $dark-blue;
      }
      
      dd {
        margin: 0;
        color: $primary;
      }
    }
  }

  // Abstract
  &__abstract {
    margin: 3rem 0;
    
    h2 {
      font-size: 2.4rem;
      color: $dark-blue;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.6;
      color: $primary;
    }
  }

  // Main Content
  &__content {
    h2, h3, h4 {
      color: $dark-blue;
      margin: 3rem 0 1.5rem;
    }

    p {
      margin-bottom: 1.5rem;
      font-size: 1.8rem;
      line-height: 1.6;
      color: $primary;
    }

    figure {
      margin: 3rem 0;
      
      img {
        max-width: 100%;
        height: auto;
      }
      
      figcaption {
        margin-top: 1rem;
        font-size: 1.6rem;
        color: $darkest-grey;
        text-align: center;
      }
    }
  }

  // Citations & References
  &__references {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid $regular-grey;
    
    h2 {
      font-size: 2.4rem;
      color: $dark-blue;
      margin-bottom: 2rem;
    }
    
    .reference {
      margin-bottom: 1.5rem;
      padding-left: 2.5rem;
      text-indent: -2.5rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: $primary;
    }
  }

  // Publication Types
  &--journal {
    .report__meta {
      background: rgba($blue, 0.1);
    }
  }

  &--conference {
    .report__meta {
      background: rgba($green, 0.1);
    }
  }

  &--preprint {
    .report__meta {
      background: rgba($yellow, 0.1);
    }
  }

  &--policy {
    .report__meta {
      background: rgba($violet, 0.1);
    }
  }

  // DOI Link
  &__doi {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.6rem;
    color: $blue;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__links {
    margin: 3rem 0;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;

    .button {
      display: inline-flex;
      align-items: center;
      gap: 1rem;
      
      svg {
        width: 2rem;
        height: 2rem;
      }

      &--standard {
        background-color: $green;
        
        &:hover {
          background-color: darken($green, 10%);
        }
      }
    }
  }

  // Bibliography Section
  &__bibliography {
    margin-top: 4rem;
    
    .bibhr {
      margin: 2rem 0;
      border: 0;
      border-top: 1px solid $regular-grey;
    }

    .report__links {
      margin: 2rem 0;

      .button--toggle {
        width: 100%;
        max-width: 300px;
        padding: 1rem 2rem;
        background-color: $green;
        border: none;
        border-radius: 32px;
        font-family: $header-font-family;
        font-size: 1.6rem;
        color: $dark-blue;
        cursor: pointer;
        transition: background-color 0.3s $cubic;
        appearance: none;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2305385e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 2rem;

        &:hover {
          background-color: darken($green, 10%);
        }

        option {
          background-color: $lightest;
          color: $dark-blue;
        }
      }

      .citation-options {
        display: none;
        max-width: 300px;
        margin-top: 1rem;
        padding: 2rem;
        background: $lightest;
        border: 1px solid $regular-grey;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba($darkest, 0.1);

        &.is-active {
          display: block;
        }

        .citation-format {
          h4 {
            margin: 0 0 1.5rem 0;
            font-size: 1.6rem;
            color: $dark-blue;
          }

          .citation-actions {
            display: flex;
            gap: 1rem;
            margin-bottom: 1.5rem;

            .button--small {
              padding: 0.5rem 1rem;
              font-size: 1.4rem;
              background-color: $green;
              color: $dark-blue;
              border: none;
              border-radius: 16px;
              cursor: pointer;
              transition: background-color 0.3s $cubic;

              &:hover {
                background-color: darken($green, 10%);
              }
            }
          }
        }

        textarea {
          width: 100%;
          height: 100px;
          padding: 1rem;
          border: 1px solid $regular-grey;
          border-radius: 4px;
          font-family: monospace;
          font-size: 1.4rem;
          resize: none;
        }
      }
    }
  }

  &__citation-panel {
    margin-top: 2rem;
    padding: 2rem;
    background: $lightest;
    border: 1px solid $regular-grey;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba($darkest, 0.1);

    .citation-format-tabs {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $regular-grey;
      padding-bottom: 1rem;

      .tab-button {
        padding: 0.5rem 1.5rem;
        font-size: 1.6rem;
        color: $dark-blue;
        background: none;
        border: none;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: all 0.3s $cubic;

        &:hover {
          color: $blue;
        }

        &.active {
          color: $blue;
          border-bottom-color: $blue;
          font-weight: $global-weight-bold;
        }
      }
    }

    .citation-format {
      display: none;

      &.active {
        display: block;
      }

      .citation-actions {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;

        .button--small {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1.5rem;
          font-size: 1.4rem;
          background-color: $green;
          color: $dark-blue;
          border: none;
          border-radius: 32px;
          cursor: pointer;
          text-decoration: none;
          transition: background-color 0.3s $cubic;

          svg {
            width: 1.6rem;
            height: 1.6rem;
          }

          &:hover {
            background-color: darken($green, 10%);
          }
        }
      }

      textarea {
        width: 100%;
        height: 150px;
        padding: 1rem;
        border: 1px solid $regular-grey;
        border-radius: 4px;
        font-family: monospace;
        font-size: 1.4rem;
        resize: vertical;
      }
    }
  }

  &__social-share {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    
    a {
      width: 35px;
      height: auto;
      margin-bottom: 0;
      line-height: 0;
      text-decoration: none;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
      
      &:hover {
        opacity: 0.8;
      }
    }
  }
} 