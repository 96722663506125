.block__text {
  background: $lightest;
  
  &.block__text--split {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .text__heading {
      width: 100%;
      margin-bottom: 2rem;

      @include bp(desktop) {
        width: 50%;
        margin-bottom: 0;
      }

      h2 {
        margin-bottom: 0;
        font-size: 2.4rem;
        line-height: 3rem;

        @include bp(tablet) {
          font-size: 3.2rem;
          line-height: 4.2rem;
        }

        // @include bp(desktop-xl) {
        //   font-size: 3.6rem;
        //   line-height: 4.8rem;
        // }
      }
    }

    .text__content {
      width: 100%;

      @include bp(desktop) {
        width: calc(50% - 7.5rem);
      }

      @include bp(desktop-xl) {
        width: calc(50% - 15rem);
      }

      p,
      li,
      a:not(.button) {
        font-size: 1.8rem;
        line-height: 2.4rem;

        @include bp(tablet) {
          font-size: 2rem;
          line-height: 2.6rem;
        }
      }

      p {
        margin-top: 0;
      }
    }
  }
}
