.block__media-text {
  &.in-view {
    .media-text__media {
      .media-text__media-image {
        @include animate(400ms, 700ms, fadeIn);
        opacity: 1;
      }
      .media-text__media-image--floating {
        @include animate(400ms, 900ms, fadeInDown);
        opacity: 1;
      }
    }

    .media-text__content {
      @include animate(400ms, 400ms, fadeIn);
      opacity: 1;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .media-text__media {
    width: 100%;
    margin-bottom: 5rem;
    position: relative;

    @include bp(tablet) {
      margin-bottom: 10rem;
    }

    @include bp(desktop) {
      width: calc(50% - 5rem);
      margin-bottom: 0;
    }

    .study & { 
      @include bp(desktop) {
        width: calc(45% - 5rem);
        margin-bottom: 0;
      }
    }

    .media-text__media-image {
      width: 100%;
      height: auto;
      display: block;
      opacity: 0;

      .study & {
        border-radius: 20px;
      }

      &--floating {
        width: 35%;
        height: auto;
        position: absolute;
        bottom: -4rem;
        right: 2rem;
        display: block;
        opacity: 0;

        @include bp(tablet) {
          width: 255px;
          bottom: -6rem;
          right: 3rem;
        }

        .study & {
          @include bp(tablet) {
            width: 155px;
            bottom: -3rem;
            right: 1.5rem;
          }
        }
      }
    }
  }

  .media-text__content {
    width: 100%;
    opacity: 0;

    @include bp(desktop) {
      width: calc(50% - 5rem);
    }

    h2 {
      margin-bottom: 2rem;
      font-size: 2.4rem;
      line-height: 3rem;
      color: $dark-blue;

      @include bp(tablet) {
        font-size: 3rem;
        line-height: 4rem;
      }

      @include bp(desktop) {
        font-size: 3.8rem;
        line-height: 4.9rem;
      }

      // @include bp(desktop-xl) {
      //   margin-bottom: 3rem;
      //   font-size: 4.2rem;
      //   line-height: 5.4rem;
      // }
    }

    p {
      margin-top: 0;
      font-size: 1.8rem;
      line-height: 2.4rem;

      @include bp(tablet) {
        font-size: 2rem;
        line-height: 2.6rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .button-box {
      margin-top: 2rem;
    }

    .media-text__content-quicklinks {
      margin: 3rem 0;
      padding: 0;

      li {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        list-style: none;
        border-bottom: 2px solid $darkest;

        &:last-of-type {
          margin-bottom: 0;
        }

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: $header-font-family;
          font-size: 1.8rem;
          line-height: 2.3rem;
          color: $darkest;
          text-decoration: none;
        }

        svg {
          width: 15px;
          height: auto;
          display: block;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
