.block__paper {
  overflow: hidden;

  &.in-view {
    .paper__content {
      @include animate(400ms, 400ms, fadeIn);
      opacity: 1;
    }

    .paper__form {
      @include animate(400ms, 700ms, fadeInDown);
      opacity: 1;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .paper__content {
    width: 100%;
    opacity: 0;

    @include bp(tablet) {
      width: 50%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    a:not(.button) {
      color: $dark-blue;
    }

    h2 {
      margin-bottom: 2rem;
      font-size: 3rem;
      line-height: 4rem;

      @include bp(tablet) {
        margin-bottom: 3rem;
        font-size: 4.2rem;
        line-height: 5.4rem;
      }
    }
  }

  .paper__form {
    width: 100%;
    padding: 3rem;
    position: relative;
    background-color: $primary;
    border-radius: 20px;
    opacity: 0;

    &::before {
      content: "";
      width: 550px;
      height: 550px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(35%, 30%);
      display: block;
      background-image: url("../../assets/images/pattern-bg.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include bp(tablet) {
      width: calc(50% - 5rem);
    }

    @include bp(desktop) {
      width: calc(50% - 15rem);
      padding: 6rem;
    }

    @include bp(desktop-xl) {
      width: calc(50% - 15rem);
      padding: 8rem;
    }

    &-inner {
      position: relative;
      z-index: 1;
    }

    p {
      margin-top: 0;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.8rem;
      color: $lightest;

      @include bp(tablet) {
        font-size: 2.2rem;
        line-height: 3.6rem;
      }

      @include bp(desktop-xl) {
        font-size: 2.6rem;
        line-height: 4.3rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .button-box {
      margin-top: 4rem;
    }
  }
}
