.block__quick-links {
  background: $lightest;

  .quick-links__grid {
    margin: 0 0 -2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include bp(tablet) {
      margin: 0 -1rem -2rem -1rem;
      flex-direction: row;
    }

    @include bp(desktop) {
      margin: 0 -1.5rem -3rem -1.5rem;
    }
  }

  .quick-links__link {
    width: 100%;
    height: 60px;
    margin: 0 0 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $pink;
    border-radius: 100px;
    font-family: $header-font-family;
    font-size: 2.4rem;
    line-height: 3rem;
    text-align: center;
    text-decoration: none;
    color: $dark-blue;

    @include bp(tablet) {
      width: calc(33.33% - 2rem);
      height: 80px;
      margin: 0 1rem 2rem 1rem;
    }

    @include bp(desktop) {
      width: calc(33.33% - 3rem);
      margin: 0 1.5rem 3rem 1.5rem;
      font-size: 3.2rem;
      line-height: 6rem;
    }
  }
}
