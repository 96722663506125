// Global settings
$global-width: 1440px;
$global-font-family: "Mark W01 Regular", Arial, "Lucida Grande", sans-serif;
$global-font-size: 1.8rem;
$text-color: #4a4a4a;
$global-margin: 2rem;
$global-padding: 2rem;
$global-left: 1rem;
$global-weight-normal: 400;
$global-weight-bold: 700;
$image-path: "../../assets/images/";

// Colour Scheme
$primary: #101827;

$pink: #f8edeb;
$blue: #5963f5;
$dark-blue: #05385e;

$yellow: #ffae0d;
$light-yellow: #f7b93e;
$orange: #db3900;
$green: #87e3c7;
$dark-green: #17483d;
$cream: #d0d2c6;
$bright-pink: #e5007d;
$violet: #bfc2e3;

$charcoal: #2a2a2a;
$light-cream: #f4f5f0;
$dark-cream: #ced0c8;
$regular-grey: #cccccc;
$light-grey: #f5f5f5;
$medium-grey: #d8d8d8;
$dark-grey: #979797;
$darkest-grey: #6a6a6a;
$dark: #353535;
$darker: #4a4a4a;
$darkest: #040404;
$light: #eaeaea;
$lighter: #f2f2f2;
$lightest: #ffffff;

// Typography settings
$baseline: 1.5;
$scale: 1.614;

// Set our base h1 font size then use the golden ratio to calculate the rest

$h1: 4.2rem;
$h2: 3.8rem;
$h3: 3.2rem;
$h4: 2.8rem;
$h5: 2.4rem;
$h6: 2.0rem;

$header-font-family: "Mark W01 Bold", Arial, "Lucida Grande", sans-serif;
$header-line-height: 1.2;
$header-bottom-margin: rem-calc(30);
$paragraph-line-height: 1.6;
$paragraph-margin-bottom: 1.2rem;
$list-lineheight: $paragraph-line-height;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-grey;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-grey;
$cite-font-size: rem-calc(13);
$cite-color: $dark-grey;
$cite-pseudo-content: "\2014 \0020";
$abbr-underline: 1px dotted $dark;

// inputs
$input-height: 50px;
$input-border-color: $lightest;
$input-focus-color: $primary;
$input-focus-border-color: $primary;

// tables
$table-striped: even;
$table-striped-background: $lightest;
$table-header-background: #eeeeee;
$table-header-padding: 1rem;
$table-header-weight: $global-weight-bold;
$table-body-background: #ffffff;
$table-body-padding: 1rem;
$table-body-weight: $global-weight-normal;
$table-footer-background: #eeeeee;
$table-footer-padding: 1rem;
$table-footer-weight: $global-weight-normal;

// Grid
$breakpoints: (
  "mobile": (
    max-width: 640px
  ),
  "tablet": (
    min-width: 768px
  ),
  "phablet": (
    min-width: 960px
  ),
  "desktop": (
    min-width: 1200px
  ),
  "desktop-xl": (
    min-width: 1600px
  )
);

// We use these to make sure border radius matches unless we want it different.
$global-radius: 4px;
$global-rounded: 1000px;

// Animations
$cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
