header[role="banner"] {
  width: 100%;
  height: auto;
  position: sticky;
  top: -65px;
  left: 0;
  background-color: $darkest;
  transition: 400ms $cubic;
  z-index: 9999;

  @include bp(tablet) {
    top: -90px;
  }

  &.in-view {
    .header__upper {
  
    }
  }

  .header__upper {
    padding: 1.5rem 0;

    @include bp(desktop) {
      padding: 2rem 0;
    }

    .university-of-cambridge img {
      width: 120px;
      height: auto;
      display: block;

      @include bp(desktop) {
        width: 180px;
      }
    }

    .cambridge-uni-quicklinks-footer {
      display: block;
      font-family: $header-font-family;
      font-size: 1.2rem;
      line-height: normal;
      // font-weight: 700;
      text-decoration: none;
      color: $lightest;

      @include bp(tablet) {
        display: none;
      }
    }

    .cambridge-uni-quicklinks {
      display: none;

      @include bp(tablet) {
        display: block;
      }

      a {
        display: inline-block;
        font-family: $header-font-family;
        font-size: 1.2rem;
        line-height: normal;
        // font-weight: 700;
        color: $lightest;
        text-decoration: none;

        &::after {
          content: "|";
          margin-left: 1rem;
          margin-right: 1rem;
          display: inline-block;
          color: $lightest;
        }

        &:last-child {
          &::after {
            content: "";
            display: none;
          }
        }
      }
    }
  }

  .header__nav {
    width: 90%;
    max-width: $global-width;
    margin: 0 auto;
    position: absolute;
    bottom: -2rem;
    left: 0;
    right: 0;
    transform: translateY(100%);
    z-index: 2;

    @include bp(tablet) {
      bottom: -3rem;
    }

    &.in-view {
      .header__nav-inner {
        @include animate(400ms, 600ms, fadeInDown);
        opacity: 1;
      }
    }

    &-inner {
      width: 100%;
      height: 60px;
      padding: 1rem 1rem 1rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $lightest;
      border-radius: 50px;
      box-shadow: 0 0 20px rgba($darkest, 0.3);
      opacity: 0;

      @include bp(tablet) {
        height: 75px;
        padding: 1.5rem 1.5rem 1.5rem 2rem;
      }

      @include bp(desktop) {
        height: 100px;
        padding: 2rem 2rem 2rem 4rem;
      }

      &.search-open {
        .header__nav-inner__main {
          .nav-inner__main-search {
            clip-path: inset(0 0 0 0%);
          }
        }

        .header__nav-inner__toggle {
          svg {
            opacity: 0;
            transition-delay: 0ms;
          }

          span {
            opacity: 1;
            transition-delay: 0ms;
          }
        }
      }

      .header__nav-inner__main {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-inner__main-nav {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 600ms $cubic;

          img {
            width: 120px;
            height: auto;
            display: block;

            @include bp(tablet) {
              width: 140px;
            }

            @include bp(desktop) {
              width: 180px;
            }
          }

          .nav-inner__main-nav__inner {
            display: flex;
            align-items: center;
          }

          .button.button--standard {
            margin: 0 0 0 1rem;
            padding: 0.75rem 1.4rem;
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: $lightest;

            @include bp(tablet) {
              margin: 0 0 0 2rem;
              padding: 1rem 2rem;
              font-size: 2rem;
              line-height: 2.5rem;
            }

            @include bp(desktop) {
              margin: 0 0 0 4rem;
              padding: 1.5rem 2rem;
            }

            @include bp(desktop-xl) {
              margin: 0 0 0 5rem;
            }
          }
        }

        .nav-inner__main-search {
          width: calc(100% - 40px);
          height: 100%;
          padding: 1rem 1rem 1rem 1.5rem;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $lightest;
          border-radius: 50px;
          clip-path: inset(0 0 0 100%);
          transition: 600ms $cubic;

          @include bp(tablet) {
            width: calc(100% - 50px);
          }

          @include bp(desktop) {
            width: calc(100% - 55px);
            padding: 2rem 1rem 2rem 2rem;
          }

          form {
            width: 88%;
            height: 100%;
            display: flex;

            @include bp (desktop) {
              width: 100%;
            }

            input {
              flex: 1;
              height: 100%;
              margin: 0;
              border-radius: 50px;

              @include bp(desktop) {
                width: auto; 
                padding: 2rem;
              }
            }

            button {
              width: 40px;
              min-width: 40px;
              height: 40px;
              margin: 0 0 0 1rem;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0;
              line-height: 0;
              color: transparent;

              @include bp(tablet) {
                min-width: 150px;
                height: 100%;
                margin: 0 0 0 2rem;
                padding: 1rem 2rem;
                justify-content: space-between;
                font-size: 2rem;
                line-height: 2.5rem;
                color: $primary;
              }

              svg {
                width: 25px;
                height: auto;
                display: block;
              }
            }
          }
        }
      }

      .header__nav-inner__toggle {
        margin-left: 1rem;
        padding: 0;
        position: relative;
        background-color: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;

        @include bp(tablet) {
          margin-left: 2rem;
        }

        svg {
          width: 25px;
          height: auto;
          display: block;
          opacity: 1;
          transition: 400ms $cubic;
          transition-delay: 300ms;
        }

        span {
          width: 25px;
          height: 25px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          opacity: 0;
          transition: 400ms $cubic;
          transition-delay: 300ms;

          &::before,
          &::after {
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            background-color: $darkest;
          }

          &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          &::after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
}
