// Font settings
@font-face {
  font-family: "Mark W01 Regular";
  src: url("../fonts/6215362/be19d19f-d253-4979-a6d6-fd5c51d10387.woff2")
      format("woff2"),
    url("../fonts/6215362/e478d528-f58a-473a-bf84-d8b21b7f7107.woff")
      format("woff");
}
@font-face {
  font-family: "Mark W01 Bold";
  src: url("../fonts/6460613/da4f22ef-a1b6-401a-8a40-56954ce5141d.woff2")
      format("woff2"),
    url("../fonts/6460613/c061ab4d-74ab-4c1c-9399-152de03f973d.woff")
      format("woff");
}

// Load settings
@import "settings";
@import "mixins";

// Import common styles
@import "common/reset";
@import "common/grid";
@import "common/global";
@import "common/typography";
@import "common/animations";
@import "common/vendor";

// Import compnent styles
@import "components/buttons";
@import "components/forms";
@import "components/lists";
@import "components/tables";
@import "components/accordions";

// Import layout styles
@import "layouts/header";
@import "layouts/navigation";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/report";

// Import block styles
@import "blocks/blocks";
@import "blocks/hero";
@import "blocks/media-text";
@import "blocks/paper";
@import "blocks/accordions";
@import "blocks/latest-overview";
@import "blocks/slider";
@import "blocks/text";
@import "blocks/team";
@import "blocks/contact";
@import "blocks/opportunities";
@import "blocks/courses";
@import "blocks/quick-links";
@import "blocks/logo-grid";
@import "blocks/team-profile";
@import "blocks/project-overview";

// Import social icons
@import "social-icons";
