nav.nav-primary {
  display: none;
  align-items: center;

  @include bp(desktop) {
    display: flex;
  }

  ul {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin-right: 20px;

    li {
      margin-left: 1rem;
      padding-bottom: 1rem;
      position: relative;
      transform: translateY(5px);

      @include bp(tablet) {
        margin-left: 2rem;
      }

      @include bp(desktop) {
        margin-left: 4rem;
      }

      @include bp(desktop-xl) {
        margin-left: 5rem;
      }

      &:hover,
      &:focus,
      &:focus-within {
        .submenu {
          opacity: 1;
          visibility: visible;
        }
      }

      a,
      span {
        font-family: $header-font-family;
        font-size: 1.4rem;
        line-height: 2rem;
        text-decoration: none;
        color: $dark-blue;
        cursor: pointer;

        @include bp(desktop) {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }

      .submenu {
        width: auto;
        min-width: 200px;
        height: auto;
        position: absolute;
        left: 0;
        bottom: -23px;
        transform: translate(-10%, 100%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: $lightest;
        opacity: 0;
        visibility: hidden;
        transition: 400ms $cubic;
        box-shadow: 5px 10px 10px rgba($darkest, 0.2);
        border-radius: 0 0 8px 8px;

        a {
          display: block;
          width: 100%;
          font-family: $header-font-family;
          font-size: 1.4rem;
          padding: 2rem;
          border-bottom: 1px solid $light-grey;
          line-height: 2rem;
          text-decoration: none;
          text-align: left;
          white-space: nowrap;
          color: $dark-blue;

          &:last-child {
            border: none;
          }

          @include bp(desktop) {
            font-size: 2rem;
            line-height: 2.5rem;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

nav.nav-mobile {
  position: relative;
  transform: translateY(-60px);

  span {
    font-size: 2.8rem;
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
      margin-bottom: 2rem;
      width: 100%;
      text-align: center;
      color: $lightest;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      a {
        font-size: 2.8rem;
        line-height: 2rem;
        color: $lightest;
        text-decoration: none;
        text-align: center;
      }
    }
  }

  .submenu {
    background: rgba($lightest, 0.1);
    padding: 0.5rem 0;
    margin: 1rem 0;
    border-radius: 4px;

    a {
      font-size: 2.2rem!important;
      padding: 1rem 0;
      color: $lightest;
      opacity: 0.9;

      &:hover {
        opacity: 1;
     }
    }
  }
}

/* Hide submenus by default */
.submenu {
  display: none;
  position: relative;
  z-index: 1; /* Ensure the submenu appears above other content */
  margin: 1rem 0;
  transition: all0.3s ease;

  &.open {
    display: block;
    animation: fadeIn 0.3s ease;
  }
}

/* Style the submenu links */
.submenu a {
  display: block;
  padding: 10px 15px; /* Adjust padding as needed */
  font-size: 18px !important;
  text-decoration: none;
  color: #333; /* Text color of submenu items */
}

/* Style the submenu links on hover */
.submenu a:hover {
}

/* Adjust submenu positioning to push content down */
.nav-mobile li {
  position: relative;
}

.submenu {
  top: 100%; /* Position the submenu below the parent li */
  left: 0;
  width: 100%;

  @include bp(desktop) {
    top: unset;
  }
}

.menu-trigger {
  width: 30px;
  height: 30px;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include bp(tablet) {
    margin-left: 2rem;
  }

  @include bp(desktop) {
    display: none;
  }

  &.menu-open {
    .menu-trigger__button {
      span {
        background: 0 !important;

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
          width: 100%;
        }

        &::before,
        &::after {
          top: 0;
        }

        &,
        &::before,
        &::after {
          transition: 300ms background $cubic 200ms, 300ms top $cubic,
            300ms transform $cubic 300ms;
        }
      }
    }
  }

  .menu-trigger__button {
    width: 30px;
    height: 20px;
    order: 2;
    position: relative;
    display: block;
    transition: 600ms $cubic;
    cursor: pointer;
    background: transparent;
    border: none;

    span {
      &,
      &::before,
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 0;
        background: $dark-blue;
        transition: 300ms background $cubic, 300ms top $cubic 200ms,
          200ms transform $cubic;
      }

      &::before {
        top: -10px;
      }

      &::after {
        top: 10px;
      }

      &::before,
      &::after {
        transform: rotate(0deg);
      }

      &.mobile-menu-active {
        background: 0 !important;

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
          width: 100%;
        }

        &::before,
        &::after {
          top: 0;
          background-color: $lightest;
        }

        &,
        &::before,
        &::after {
          transition: 300ms background $cubic 200ms, 300ms top $cubic,
            300ms transform $cubic 300ms;
        }
      }
    }
  }
}
