.block__project-overview {
  .overview__heading {
  }

  .overview__content {
    p {
      margin-top: 0;
    }
  }
}
