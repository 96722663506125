.block__courses {
  .courses__table {
    width: auto;
    display: block;
    overflow: auto;

    @include bp(desktop) {
      width: 100%;
    }

    thead {
      display: none;

      @include bp(tablet) {
        display: block;
        width: auto;
        margin-bottom: 4rem;
        display: block;
        background-color: transparent;
      }

      @include bp(desktop) {
        width: 100%;
      }

      @include bp(desktop-xl) {
        margin-bottom: 6rem;
      }

      tr th:nth-child(3) h3 {
        @include bp(desktop) {
          text-align: right;
        }
      }
    }

    tbody {
      width: auto;
      display: block;
      background-color: transparent;

      @include bp(desktop) {
        width: 100%;
      }

      tr {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        border-bottom: 2px solid $darkest;

        @include bp(desktop) {
          width: auto;
          min-width: unset;
        }

        @include bp(desktop-xl) {
          margin-bottom: 4rem;
          padding-bottom: 4rem;
        }

        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }

    tr {
      width: auto;
      display: flex;
      flex-wrap: wrap;
      background-color: transparent;
      flex-direction: column;

      @include bp(tablet) {
        flex-direction: row;
        wrap: no-wrap;
      }

      @include bp(desktop) {
        width: 100%;
      }

      th:nth-child(1),
      td:nth-child(1) {
        @include bp(tablet) {
          width: 30%;
          min-width: unset;
        }
      }

      th:nth-child(2),
      td:nth-child(2) {
        @include bp(tablet) {
          width: 30%;
          min-width: unset;
        }
      }

      th:nth-child(3),
      td:nth-child(3) {
        @include bp(tablet) {
          width: 40%;
          min-width: unset;
          justify-content: flex-end;
        }
      }
    }

    th,
    td {
      padding: 0;
      background-color: transparent;
    }

    td {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:first-child p {
        font-weight: bold;
      }
    }

    &-headings {
      h3 {
        margin: 0;
        font-size: 2.4rem;
        line-height: 3rem;
        text-align: left;

        @include bp(desktop-xl) {
          font-size: 2.8rem;
          line-height: 3.5rem;
        }
      }
    }

    &-course {
      p {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $dark-blue;

        @include bp(desktop-xl) {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }

      .button.button--standard {
        margin: 0;
      }
    }
  }
}
