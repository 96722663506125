.block__logo-grid {
  .logo-grid__intro {
    margin-bottom: 5rem;

    h2 {
      margin-bottom: 2rem;
    }

    p {
      margin-top: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .logo-grid__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @include bp(tablet) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 60px;
    }

    @include bp(desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 80px;
    }
  }

  .logo-grid__grid-item {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 75px;
      display: block;
    }
  }
}
