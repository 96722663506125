// Grid Styles
.container {
    width: 90%;
    max-width: 1440px;;
    margin: 0 auto;
}

.container--wide {
    width: 90%;
    max-width: calc(1440px * 1.2);
    margin:0 auto;
}

.container--narrow {
    width: 90%;
    max-width: calc(1440px / 1.2);
    margin: 0 auto;
}

.container--thin {
    width: 90%;
    max-width: calc(1440px / 1.5);
    margin: 0 auto;
}

.container--blog {
    width: 90%;
    max-width: calc(1440px / 1.5);
    margin: 0 auto;
}

.container--divider {
    height: 4px;
    background-color: $dark-cream;;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.row {
    display: flex;
    flex-wrap: wrap;

    &--collapse > .column {
        padding-left:0!important;
        padding-right:0!important;
    }

    &--nowrap {
        flex-wrap:nowrap;
    }

    // handle horizontal alignment

    &--center {
        justify-content:center;
    }

    &--justified {
        justify-content:space-between;
    }

    &--spaced {
        justify-content:space-around;
    }

    &--start {
        justify-content: flex-start
    }

    &--end {
        justify-content:flex-end;
    }

    // handle vertical alignment

    &--middle {
        align-items:center;
    }

    &--bottom {
        align-items:flex-end;
    }

    &--top {
        align-items:flex-start;
    }

}

ul.row {
    list-style:none;
    padding:0;
    margin:0;
}

.column {
    width: 100%;
    min-width: 0;
    margin:0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis:auto;
}


@for $i from 1 through 12 {

    .column-m-#{$i} {
        flex-basis: calc(100% / 12 * #{$i} - 2%);
        max-width: calc(100% / 12 * #{$i} - 2%);
    }

    .column-m-#{$i}-nest {
        flex-basis: calc(100% / 12 * #{$i});
        max-width: calc(100% / 12 * #{$i});
    }

    .column-m-order-#{$i} {
        order:#{$i};
    }

}

@include bp(tablet) {

    @for $i from 1 through 12 {

        .column-t-#{$i} {
            flex-basis: calc(100% / 12 * #{$i} - 2%);
            max-width: calc(100% / 12 * #{$i} - 2%);
        }

        .column-t-#{$i}-nest {
            flex-basis: calc(100% / 12 * #{$i});
            max-width: calc(100% / 12 * #{$i});
        }

        .column-t-order-#{$i} {
            order:#{$i};
        }

    }

}

@include bp(phablet) {

    @for $i from 1 through 12 {

        .column-p-#{$i} {
            flex-basis: calc(100% / 12 * #{$i} - 2%);
            max-width: calc(100% / 12 * #{$i} - 2%);
        }

        .column-p-#{$i}-nest {
            flex-basis: calc(100% / 12 * #{$i});
            max-width: calc(100% / 12 * #{$i});
        }

        .column-p-order-#{$i} {
            order:#{$i};
        }
    }
}

@include bp(desktop) {

    @for $i from 1 through 12 {

        .column-d-#{$i} {
            flex-basis: calc(100% / 12 * #{$i} - 2%);
            max-width: calc(100% / 12 * #{$i} - 2%);
        }

        .column-d-#{$i}-nest {
            flex-basis: calc(100% / 12 * #{$i});
            max-width: calc(100% / 12 * #{$i});
        }

        .column-d-order-#{$i} {
            order:#{$i};
        }
    }
}

// .column-m-12 {
//     flex-basis: 100%;
//     max-width: 100%;
// }

// .column-t-12 {

//     @include bp(tablet) {
//         flex-basis: 100%;
//         max-width: 100%;   
//     }
// }

// .column-d-12 {

//     @include bp(desktop) {
//         flex-basis: 100%;
//         max-width: 100%;   
//     }
// }

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 { 
    padding-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}