.block {
  position: relative;
  padding: 3rem 0;

  @include bp(tablet) {
    padding: 4rem 0;
  }

  @include bp(desktop) {
    padding: 8rem 0;
  }

  &.block--bg-dark-blue {
    background-color: $dark-blue;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    a:not(.button) {
      color: $lightest;
    }
  }

  &.block--bg-pink {
    background-color: $pink;
  }

  &.block--bg-light {
    background-color: $lightest;
  }

  // Padding Controls
  &.block--no-padding-top {
    padding-top: 0;

    @include bp(desktop) {
      padding-top: 0;
    }
  }

  &.block--no-padding-bottom {
    padding-bottom: 0;

    @include bp(desktop) {
      padding-bottom: 0;
    }
  }

  &.block--increase-padding-top {
    padding-top: 6rem;

    @include bp(desktop) {
      padding-top: 12rem;
    }
  }

  &.block--increase-padding-bottom {
    padding-bottom: 6rem;

    @include bp(desktop) {
      padding-bottom: 12rem;
    }
  }
}
