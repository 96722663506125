.block__slider {
  background: $lightest;
  overflow: hidden;
  
  &.block--bg-dark-blue {
    .slider__slide {
      &-heading {
        h3 {
          color: $lightest;
        }
      }

      &-content {
        p {
          color: $lightest;
        }
      }
    }
  }

  &.block--bg-pink {
    .slider__slide {
      &-heading {
        h3 {
          color: $dark-blue;
        }
      }

      &-content {
        p {
          color: $dark-blue;
        }
      }
    }
  }

  .container {
    position: relative;
  }

  .slider__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include bp(desktop) {
      margin-bottom: 7.5rem;
    }
  }

  .slider__notice {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }

  .slider__intro {
    @include bp(desktop) {
      max-width: 75%;
    }
  }

  .slider__intro h2 {
    margin-bottom: 0;
  }

  .slider__main {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;

    .slick-list {
      margin: 0 -2rem;
      overflow: visible;
    }

    .slick-slide {
      margin: 0 2rem;
    }

    .slider__slide {
      width: 100%;
      margin-bottom: 2rem;
      text-decoration: none;

      @include bp(tablet) {
        width: calc(50% - 1.5rem);
        margin-right: 3rem;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &-image {
        margin-bottom: 2rem;
        position: relative;

        @include bp(desktop) {
          margin-bottom: 3rem;
        }

        &::before {
          content: "";
          padding-top: 56.25%;
          display: block;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      &-heading {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include bp(desktop) {
          margin-bottom: 2rem;
        }

        h3 {
          margin: 0;
          font-size: 2.4rem;
          line-height: 3rem;
          max-width: 85%;

          @include bp(tablet) {
            font-size: 3rem;
            line-height: 4rem;
          }

          @include bp(desktop) {
            font-size: 3.2rem;
            line-height: 4.2rem;
          }
        }

        a {
          padding: 10px;

          svg {
            width: 40px;
            height: auto;
            display: block;

            @include bp(tablet) {
              width: 50px;
            }

            @include bp(desktop) {
              width: 60px;
            }
          }
        }
      }

      &-content {
        p {
          margin-top: 0;
          font-size: 1.8rem;
          line-height: 2.6rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
