// Custom List Styles

// Lists
ul,
ol,
dl {
    margin-bottom: $list-margin-bottom;
    list-style-position: $list-style-position;
    line-height: $list-lineheight;
}

// List items
li {
    font-size: 1.8rem;
    color: $charcoal;
}

// Unordered lists
ul {
    margin-left: $list-side-margin;
    list-style-type: $list-style-type;
}

// Ordered lists
ol {
    position: relative;
    margin-left: $list-side-margin;
    counter-reset: counter;
    list-style: none;

    li {
        counter-increment: counter;
        margin-bottom: 1rem;

        &:before {
            position: absolute;
            left: 1rem;
            display: inline-block;
            content: counter(counter)".";
            font-size: 1.8rem;
            color: $charcoal;
            font-weight: bold;
        }
    }
}

// Nested unordered/ordered lists
ul,
ol {
    & & {
        margin-left: $list-nested-side-margin;
        margin-bottom: 0;
    }
}

// Definition lists
dl {
    margin-bottom: $defnlist-margin-bottom;

    dt {
        margin-bottom: $defnlist-term-margin-bottom;
        font-weight: $defnlist-term-weight;
    }
}